import Alpine from "alpinejs";
import flatpickr from "flatpickr";
//import Quill from "quill";
import * as FilePond from "filepond";
//import { createPopper } from "@popperjs/core";
import focus from "@alpinejs/focus";

import * as Sentry from "@sentry/browser";

Alpine.plugin(focus);

window.flatpickr = flatpickr;
window.FilePond = FilePond;
/*window.Quill = Quill;
window.createPopper = createPopper;*/

Sentry.init({
    dsn: "https://a78cdf809143059f33e08ee22a58a9a5@o4506111574933504.ingest.us.sentry.io/4507747585884160",
    integrations: [
        Sentry.browserTracingIntegration(),
        //Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration(),
    ],
    debug: false,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/app.docuwriter\.ai/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 1.0,
});
